:root {
  --color-primary: #0a385e;
  --color-primary-light: #acc2d4;
  --color-primary-medium-light: #0e4b7e;
  --color-primary-medium2-light: #11568f;
  --color-primary-medium3-light: #1b77c2;
  --color-primary-dark: #082a46;
  --color-secondary: #ad430e;
  --color-secondary-light: #ffe1d2;
  --color-font: #333;
  --color-green: rgb(28, 109, 32);
  --color-light-green: rgb(192, 240, 194);
  --color-red: rgb(170, 36, 36);
  --color-warning: #ca9400;
  --color-black: #000;
  --color-white: #fff;
  --color-light-grey: #eee;
  --color-grey: #ccc;
  --color-gold: #ffd700;
  --color-silver: #ddd;
  --color-bronze: #cd7f32;
  --color-border: #ddd;
  --mobile-view: #ddd;

  --color-setRed: #f76451;
  --color-pointCardBlack: #333;
  --color-pointIncreament: rgb(47, 136, 30);

  --max-width: 700px;

  --font-family-body: "Rubik", sans-serif;
  --font-weight-light: 300;
  --font-weight-body: 400;
  --font-weight-bold: 600;
}
